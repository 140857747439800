<template>
  <v-dialog v-model="visible" fullscreen hide-overlay persistent eager>
    <v-btn @click="hide" class="top-level" fab small outlined fixed top right>
      <v-icon>$vuetify.icons.close</v-icon>
    </v-btn>
    <img id="kc-background1" src="/img/kc-light.jpg">
    <img id="kc-background2" src="/img/kc-dark.jpg">
    <canvas id="kc-canvas" />
  </v-dialog>
</template>

<script>
export default {
  name: 'KC',
  data () {
    return {
      engine: null,
      visible: false
    }
  },
  methods: {
    hide () {
      this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
      window.location.reload()
    },
    isDark () {
      return this.$stratus.services.localStorage.get('dark-theme', false)
    },
    onResize () {
      this.$nextTick(this.hide)
      window.removeEventListener('resize', this.onResizeFunction)
    },
    async show () {
      console.log('[kc] engaged!')
      this.visible = true

      if (!this.engine) {
        const image = this.isDark() ? 'kc-background2' : 'kc-background1'
        /* eslint-disable-next-line no-undef */
        this.engine = new RainyDay('kc-canvas', image, window.innerWidth, window.innerHeight, 1.0, 0)
        this.engine.gravity = this.engine.GRAVITY_NON_LINEAR
        this.engine.trail = this.engine.TRAIL_SMUDGE
        this.engine.rain([this.engine.preset(3, 3, 0.88), this.engine.preset(5, 5, 0.9), this.engine.preset(16, 2, 1)], 25)
        this.onResizeFunction = window.addEventListener('resize', this.onResize)
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResizeFunction)
  }
}
</script>

<style>
.v-dialog.v-dialog--fullscreen {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}

.top-level {
  z-index: 999 !important;
}

#kc-canvas {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0 auto;
  /* min-height: 320px; */
  width: 100%;
  overflow: hidden;
  z-index: 900;
}
</style>
